module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Green Stars","short_name":"GreenStars","start_url":"/","background_color":"#000000","theme_color":"#5e8c30","display":"minimal-ui","icon":"src/images/icon.png"},
    },{
      plugin: require('../node_modules/@digitaliseringsbyran/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"greenstars","defaultLang":"en-gb","accessToken":"MC5Ya3ZGbWhNQUFDRUEwVDlp.77-977-977-977-9Ge-_ve-_vQzvv71fLgzvv73vv70v77-9NnkJJe-_vUcaXO-_ve-_vVvvv71tZO-_ve-_vQ","previews":true,"pages":[{"type":"Page","match":"/:uid","path":"/page-item","component":"/codebuild/output/src1004367941/src/green-stars/src/templates/page.js"},{"type":"Client","match":"/family/:uid","path":"/client-item","component":"/codebuild/output/src1004367941/src/green-stars/src/templates/client.js"},{"type":"Article","match":"/news/:uid","path":"/article-item","component":"/codebuild/output/src1004367941/src/green-stars/src/templates/article.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-161412640-1","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
